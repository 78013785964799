import React, { useState, useRef, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { jsPDF } from "jspdf";
// import * as htmlToImage from 'html-to-image';
import { domToPng } from "modern-screenshot";
import PDFMapsDirections from "./PDFMapsDirections";
import isEqual from 'lodash.isequal'; // If using Lodash
import { updateLocation } from "../../util/db";
// import domtoimage from 'dom-to-image-more';

const useStyles = makeStyles(() => ({
    content: {
      paddingBottom: 24
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        padding: 10,
        gap: 20
    }
  }));

function CreateMapDialog(props) {
    const classes = useStyles();
    const theme = useTheme();
    const ifSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const [destinationLocationsCopy, setDestinationLocationsCopy] = useState(props?.destinationLocations ? [...props.destinationLocations] : []);
    const [originLocationsCopy, setOriginLocationsCopy] = useState(props?.fromLocations ? [...props.fromLocations] : []);
    const [to, setTo] = useState();
    const [from, setFrom] = useState();
    const canvasRef = useRef();
    const [disableUI, setDisableUI] = useState(false);
    const [generatingPDF, setGeneratingPDF] = useState(false);
    const [destinationListsChanged, setDestinationListsChanged] = useState(false);
    const [canvasWidth, setCanvasWidth] = useState("1000px");

    // make sure the copied lists from props are definitely the correct list.
    useEffect(() => {
      setDestinationLocationsCopy(props?.destinationLocations ? [...props.destinationLocations] : []);
      setOriginLocationsCopy(props?.fromLocations ? [...props.fromLocations] : []);
    }, [props.destinationLocations, props.fromLocations]);

    // check if copy and original destination lists are in sync or have changed
    useEffect(() => {
      if (isEqual(destinationLocationsCopy,props.destinationLocations)) {
        setDestinationListsChanged(false);
      } else {
        setDestinationListsChanged(true);
      }
    }, [destinationListsChanged, destinationLocationsCopy, props.destinationLocations]);

    const closeAndClear = () => {
        setTo();
        setGeneratingPDF(false);
        setFrom();
        setDestinationLocationsCopy([...props.destinationLocations]);
        props.setCreateMapDialogOpened(false);
    }
    const setFromObject = (locId) => {
        let local = originLocationsCopy?.find(i => i.id === locId);
        setFrom(local);
    }
    const setDestinationObject = (locId, locUpdatedFromDirections) => {
      if (locId) {
        setDestinationLocationsCopy([...props.destinationLocations]);
        let local = destinationLocationsCopy?.find(i => i.id === locId);
        setTo(local);
      }
      if (!locId && locUpdatedFromDirections) {
        setDestinationLocationsCopy(destinationLocationsCopy.map(loc => loc.id === locUpdatedFromDirections.id ? locUpdatedFromDirections : loc));
        setTo(locUpdatedFromDirections);
      }
    }

    const takeScreenShot = async (node) => {
      // const dataURI = await domtoimage.toPng(node);
      const scale = 2;
          const image = await domToPng(node, {
            height: node.offsetHeight * scale,
            style: {
              transform: `scale(${scale}) translate(${node.offsetWidth / 2 / scale}px, ${node.offsetHeight / 2 / scale}px)`
            },
            width: node.offsetWidth * scale
          });
      return image;
    };

    const convertScreenShotToPDF = async (img) => {
      var orientation = canvasRef.current.clientHeight > canvasRef.current.clientWidth ? 'p' : 'l';
      var pdf = new jsPDF(orientation,"px", [canvasRef.current.clientHeight/2, canvasRef.current.clientWidth/2]);
      var imgProps= pdf.getImageProperties(img);
      var pdfWidth = pdf.internal.pageSize.getWidth();
      var pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(img, 'PNG', 0, 0, pdfWidth, pdfHeight, undefined, 'FAST');

      return pdf;
    }

    const downloadPDF = (pdf) => {
      var pdfName = from.name + '-to-' + to.name + ".pdf";
      pdfName = pdfName.replace(/\s+/g, '-').toLowerCase();
      pdf.save(pdfName);
      if (destinationListsChanged) {
        updateLocation(to.id, to);
      }
    }; 

    const exportMapToPDF = (domElement) => {      
      setDisableUI(true);
      setCanvasWidth("1700px");
      setGeneratingPDF(true);
        setTimeout(() => {
          takeScreenShot(domElement)
          .then((img)=>{
            convertScreenShotToPDF(img)
              .then((pdf) => {
                downloadPDF(pdf);
                setGeneratingPDF(false);
                setDisableUI(false);
                setCanvasWidth("1000px");
              })
              .catch((convertErr) => {
                console.log('an error occurred in convert to pdf portion of map generation.. ' + convertErr);
                setGeneratingPDF(false);
                setDisableUI(false);
                setCanvasWidth("1000px");
              })
          })
        .catch((screenshotErr)=>{
          console.log('an error occurred in screenshot portion of map generation.. ' + screenshotErr);
          setGeneratingPDF(false);
          setDisableUI(false);
          setCanvasWidth("1000px");
        })  
        }, 2000);

    };
  
  return (
    <Dialog 
    disableEnforceFocus
    style={{ pointerEvents: 'none' }}
    PaperProps={{ style: { pointerEvents: 'auto' } }}
    open={props.createMapDialogOpened} onClose={props.onDone} fullScreen={ifSmall} fullWidth={true} maxWidth='xl'>
     <div className={classes.header}>
     <DialogTitle className="editHeader">Create Map</DialogTitle>
     {destinationListsChanged && <div style={{marginLeft:'auto', color: '#ff4242', border: '1px solid #ff4242', padding: '10px', marginRight: 2}}>WARNING! You've updated this destination. Saving the PDF will update the location in the original group/list.</div>}
      <Button style={{marginLeft: !destinationListsChanged ? 'auto' : ''}} variant="contained" color="secondary" size="large" onClick={closeAndClear}>
        Cancel
        </Button>
              <Button style={{marginRight: 15}}
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                onClick={()=> exportMapToPDF(canvasRef.current)}
                disabled={generatingPDF || !from || !to}
              >
                
                {generatingPDF === false && <span>SAVE PDF</span>}

                {generatingPDF === true &&  <span>CREATING PDF...</span>}
              </Button>
     </div>

      <DialogContent className={classes.content}>
      {(originLocationsCopy?.length > 0 && destinationLocationsCopy?.length > 0) && <Grid container={true} spacing={3} style={{marginBottom: 10}}>
        <Grid item={true} xs={ ifSmall ? 12 : 6 }>
          <TextField
          variant="outlined"
          id="from"
          label="FROM"
          inputProps={{ style: { textTransform: "uppercase" } }}
          name="from"
          select  
          fullWidth
          onChange={(e) => setFromObject(e.target?.value)}
          >
          {originLocationsCopy?.map(loc => (
          <MenuItem key={loc.id} value={loc.id}>
          {loc.type} {loc.setName && ` - "${loc.setName}"`} {` - ${loc.mapData.address}`}
          </MenuItem>
          ))}
          </TextField>
        </Grid>

        <Grid item={true} xs={ ifSmall ? 12 : 6 }>
          <TextField
          variant="outlined"
          id="dest"
          label="TO"
          inputProps={{ style: { textTransform: "uppercase" } }}
          name="dest"
          select  
          fullWidth
          onChange={(e) => setDestinationObject(e.target?.value)}
          >
          {destinationLocationsCopy?.map(loc => (
          <MenuItem key={loc.id} value={loc.id}>
          {loc.type} {loc.setName && ` - "${loc.setName}"`} {` - ${loc.mapData.address}`}
          </MenuItem>
          ))}
              </TextField>
      </Grid>
    </Grid>}

   {from && to &&  <div ref={canvasRef} style={{minWidth: canvasWidth}}>
    <PDFMapsDirections date={props.date} group={props.group} set={to.setName} dropdownOrigin={from} dropdownDestination={to} allGroupLocations={props.allGroupLocations} lostCall={props.lostCall} lostCallName={props.lostCallName} specialInstructions={props.specialInstructions} formatPhoneNumber={props.formatPhoneNumber} setDestinationObject={setDestinationObject} disableDefaultUI={disableUI}/>
    </div>}
      </DialogContent>
    </Dialog>
  )
}

export default React.memo(CreateMapDialog);
